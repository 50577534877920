<template>
  <div style="">

<!--    active-text-color="#ffd04b"-->
<!--    background-color="#545c64"-->
<!--    class="el-menu-vertical-demo"-->
<!--    default-active="2"-->
<!--    text-color="#fff"-->
<!--    @open="handleOpen"-->
<!--    @close="handleClose"-->


    <el-menu
        style="width: 200px; min-height: calc(100vh - 50px)"
        :default-active="active"
        router
        @select="handleSelect"
        class="el-menu-vertical-demo">
      <el-menu-item index="firstPage" style="font-size: 16px;height: 80px">
        <el-icon><View /></el-icon>
        首页</el-menu-item>
      <el-menu-item index="user" v-if="this.user.flag==='1'" style="font-size: 16px;height: 80px">
        <el-icon><User /></el-icon>
        用户管理</el-menu-item>
      <el-menu-item index="college" style="font-size: 16px;height: 80px">
        <el-icon><School /></el-icon>
        查大学</el-menu-item>
      <el-menu-item index="major" style="font-size: 16px;height: 80px">
        <el-icon><Suitcase /></el-icon>
        查专业</el-menu-item>
      <el-menu-item index="message" style="font-size: 16px;height: 80px">
        <el-icon><Reading /></el-icon>
        查资讯</el-menu-item>
      <el-menu-item index="collegeApplication" style="font-size: 16px;height: 80px">
        <el-icon><EditPen /></el-icon>
        志愿推荐</el-menu-item>
      <el-menu-item index="aiRecommend" style="font-size: 16px;height: 80px">
        <el-icon><Coordinate /></el-icon>
        AI专业推荐</el-menu-item>
      <el-menu-item index="person" style="font-size: 16px;height: 80px">
        <el-icon><User /></el-icon>
        个人中心</el-menu-item>
<!--      <el-menu-item index="password" style="font-size: 16px;height: 80px">-->
<!--        <el-icon><Lock /></el-icon>-->
<!--        密码修改</el-menu-item>-->
      <el-menu-item style="margin-left: -12px;font-size: 16px;height: 80px" >
        <el-icon style="margin-left: 10px"><Remove /></el-icon>
        <el-popconfirm title="确定退出吗?" @confirm="logout">
          <template #reference>
            <el-button type="text" style="font-size: 20px;color: black;margin-left: -6px">退出系统</el-button>
          </template>
        </el-popconfirm>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { ArrowDown,School,Suitcase,Reading,User,Lock,Remove,View,EditPen,Coordinate} from '@element-plus/icons-vue'

export default {
  name: "Aside",
  components:{
    ArrowDown,
    School,
    Suitcase,
    Reading,
    User,
    Lock,
    Remove,
    View,
    EditPen,
    Coordinate
  },
  data(){
    return{
      user:JSON.parse(sessionStorage.getItem("user"))||{},
      active:JSON.parse(sessionStorage.getItem("currentRouter")),
    }
  },
  computed: {
    active() {
      // console.log("computed")
      // console.log(this.$route.path)
      let curPath = this.$route.path
      // console.log(curPath)
      let len = curPath.length
      let path = curPath.substring(1,len)
      if(path=='collegeDetail'){
        path = 'college'
      }else if(path=='majorDetail'){
        path = 'major'
      }else if(path=='messageDetail'){
        path = 'message'
      }
      sessionStorage.setItem("currentRouter", JSON.stringify(path))  // 缓存路由高亮信息
      // console.log(path)
      return path; //监听路由，控制菜单选择
    }
  },
  created() {
    // console.log("!!!")
    // location.reload()
  },
  methods:{
    handleSelect(keyPath){
      this.active = keyPath;
      sessionStorage.setItem("currentRouter", JSON.stringify(keyPath))  // 缓存路由高亮信息
      // console.log(this.active)
    },
    logout(){
      //console.log("!!!!")
      this.$router.push("/login")
      sessionStorage.removeItem("user")
      sessionStorage.removeItem("currentRouter")
      sessionStorage.removeItem("airecommend")
      sessionStorage.removeItem("college")
      sessionStorage.removeItem("major")
      this.$message.success("退出成功")
    }
  }
}
</script>

<style scoped>

</style>