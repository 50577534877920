<template>
  <div style="height: 50px; line-height: 50px; border-bottom: 1px solid #ccc; display: flex;
  margin-bottom: 5px;font-size: 20px">
    <div style="width: 500px; padding-left: 30px; font-weight: bold; color: dodgerblue;font-size: 26px">高考志愿推荐系统</div>
    <div style="flex: 1"></div>
    <div style="width: 100px">
      <el-dropdown >
      <span class="el-dropdown-link" style="margin-top: 17px;cursor: pointer">
           {{this.user.username}}
          <i class="el-icon-arrow-down el-icon--right"></i>
        <span v-if="user.flag=='3'" style="color: #0f9876;cursor:pointer;" @click="$router.push('/login')">去登录</span>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="$router.push('/person')">个人信息</el-dropdown-item>
            <el-dropdown-item @click="$router.push('/password')">修改密码</el-dropdown-item>
            <el-popconfirm title="确定退出吗?" @confirm="logout">
              <template #reference>
                <el-button type="text">退出系统</el-button>
              </template>
            </el-popconfirm>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
 import { ArrowDown } from '@element-plus/icons-vue'
export default {
  name: "Header",
  //  ArrowDown
  components:{
    ArrowDown
  },
  props: ['user'],
  data() {
    return {
      user:JSON.parse(sessionStorage.getItem("user"))||{}
    }
  },
  created() {
    console.log(this.user)
  },
  methods:{
    logout(){
      //console.log("!!!!")
      this.$router.push("/login")
      sessionStorage.removeItem("user")
      sessionStorage.removeItem("currentRouter")
      sessionStorage.removeItem("airecommend")
      sessionStorage.removeItem("college")
      sessionStorage.removeItem("major")
      this.$message.success("退出成功")
    }
  }
}
</script>

<style scoped>

</style>