import { createRouter, createWebHistory } from 'vue-router'
import Layout from "@/layout/Layout";

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: "/firstPage",     //当访问 / 时，自动跳转到home界面
    children: [
      {
        path: 'user',
        name: 'User',
        component: () => import("@/views/User")
      },
      {
        path: 'firstPage',
        name: 'FirstPage',
        component: () => import("@/views/FirstPage")
      },
      {
        path: 'college',
        name: 'College',
        // meta: {
        //   title: '我的页面B',
        //   keepAlive: true,
        //   isBack:false      // 需要缓存
        // },
        component: () => import("@/views/College")
      },
      {
        path: 'collegeApplication',
        name: 'CollegeApplication',
        component: () => import("@/views/CollegeApplication")
      },
      {
        path: 'aiRecommend',
        name: 'AIRecommend',
        component: () => import("@/views/AIRecommend")
      },
      {
        path: 'major',
        name: 'Major',
        component: () => import("@/views/Major")
      },
      {
        path: 'person',
        name: 'Person',
        component: () => import("@/views/Person")
      },
      {
        path: 'password',
        name: 'Password',
        component: () => import("@/views/Password")
      },
      {
        path: 'message',
        name: 'Message',
        component: () => import("@/views/Message")
      },
      {
        path: 'detailMajor',
        name: 'DetailMajor',
        component: () => import("@/views/DetailMajor")
      },
      {
        path: 'collegeDetail',
        name: 'CollegeDetail',
        component: () => import("@/views/CollegeDetail")
      },
      {
        path: 'majorDetail',
        name: 'MajorDetail',
        component: () => import("@/views/MajorDetail")
      },
      {
        path: 'messageDetail',
        name: 'MessageDetail',
        component: () => import("@/views/MessageDetail")
      },
    ]
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import("@/views/Login")
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import("@/views/Register")
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next)=>{
  if(to.path==='/login'||to.path==='/register') next()
  const role=JSON.parse(sessionStorage.getItem("user"))
  if(!role&&to.path!=='/login') return next("/login")  //强制退出登陆页面
  //访问 /home 并且session中存在数据，这时候我就放行
  next()
})

export default router
