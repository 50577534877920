import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/css/global.css'
import axios from "axios";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

createApp(App).use(store).use(router).use(ElementPlus,{size: 'small',locale:zhCn}).mount('#app')

// axios.defaults.baseURL=process.env.VUE_APP_BASEURL
//
// new Vue({
//     router,
//     // render:h=>h(App)
// }).$mount('#app')
//设置反向代理，前端请求默认发送到 http://前端ip地址:你的前端端口/api




